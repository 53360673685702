import { ApolloProvider } from "@apollo/client";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider } from "@mui/material/styles";
import type { AppProps } from "next/app";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import nProgress from "nprogress";
import type { FC, PropsWithChildren } from "react";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { SplashScreen } from "src/components/loading-and-splash/splash-screen";
import { AuthConsumer, AuthProvider } from "shared/contexts/jwt-context";
import { createApolloClient } from "shared/utils/apollo-client";

import { createTheme } from "shared/theme";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import {
  isEmployeeEmail,
  isSavvyTestUserEmail,
} from "shared/utils/internal-savvy";
import { IGNORED_ERRORS } from "src/utils/error";
import type { NextPage } from "next";
import { loggedInAsAdvisorID } from "shared/utils/savvy-auth";
import ChatbotWrapper from "src/components/advisor-dashboard/chatbot-wrapper";
import assert from "assert";
import { useIsSmallScreen } from "shared/hooks/use-is-small-screen";
import { LDProvider } from "launchdarkly-react-client-sdk";

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

type EnhancedAppProps = AppPropsWithLayout;

Router.events.on("routeChangeStart", () => nProgress.start());
Router.events.on("routeChangeError", () => nProgress.done());
Router.events.on("routeChangeComplete", () => nProgress.done());

const App: FC<EnhancedAppProps> = (props) => {
  const { Component } = props;
  const pageProps = props.pageProps as PropsWithChildren<AppPropsWithLayout>;
  const isSmallScreen = useIsSmallScreen();
  const router = useRouter();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);

  const [initedLibs, setInitedLibs] = useState(false);
  useEffect(() => {
    window.onerror = (err) => {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      const errorString = err.toString();
      if (
        IGNORED_ERRORS.some((ignoredError) =>
          errorString.includes(ignoredError)
        )
      ) {
        return true;
      }
      throw new Error(errorString);
    };
    window.onstorage = (e) => {
      if (
        e.key === "accessToken" &&
        ((!e.newValue && e.oldValue) || (e.newValue && !e.oldValue))
      ) {
        window.location.reload();
      }
    };
    if (process.env["NEXT_PUBLIC_VERCEL_ENV"] === "production") {
      const dataDogKey = process.env["NEXT_PUBLIC_DATADOG_CLIENT_KEY"];
      assert(dataDogKey != null, "");
      datadogLogs.init({
        clientToken: dataDogKey,
        env: process.env["NEXT_PUBLIC_VERCEL_ENV"],
        forwardErrorsToLogs: true,
        sampleRate: 100,
        service: "savvy-pinetree-app",
        version: "1.0.0",
      });
    }
  }, []);
  const ldClientId = process.env["NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID"];
  assert(
    ldClientId != null,
    "NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID must be set in .env"
  );
  return (
    <>
      <Head>
        <title>Savvy</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="theme-color" content="#111827" />
      </Head>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <LDProvider clientSideID={ldClientId}>
          <ApolloProvider client={createApolloClient()}>
            <AuthProvider>
              <ThemeProvider
                theme={createTheme({
                  direction: "ltr",
                  responsiveFontSizes: true,
                })}
              >
                <CssBaseline />
                <Toaster
                  position={isSmallScreen ? "top-center" : "bottom-left"}
                  toastOptions={{
                    style: {
                      width: isSmallScreen ? "330px" : "auto",
                    },
                  }}
                  reverseOrder
                />
                <AuthConsumer>
                  {(auth) => {
                    if (!auth.isInitialized) {
                      return <SplashScreen growAnimationDuration={1000} />;
                    }

                    const isAuthenticated = auth.isAuthenticated;
                    const user = auth.user;

                    if (!isAuthenticated || !user) {
                      return <Component {...pageProps} />;
                    }
                    if (
                      process.env["NEXT_PUBLIC_VERCEL_ENV"] === "production" ||
                      router.query["forceRUM"]
                    ) {
                      datadogLogs.addLoggerGlobalContext("user_id", user.id);
                      datadogLogs.addLoggerGlobalContext(
                        "is_internal_user",
                        isEmployeeEmail(user.email) ||
                          isSavvyTestUserEmail(user.email)
                      );

                      if (!initedLibs) {
                        if (
                          router.query["forceRUM"] ||
                          (!isEmployeeEmail(user.email) &&
                            !isSavvyTestUserEmail(user.email))
                        ) {
                          const applicationId =
                            process.env["NEXT_PUBLIC_DATADOG_RUM_APP_ID"];
                          assert(
                            applicationId != null,
                            ".env variable NEXT_PUBLIC_DATADOG_RUM_APP_ID must be set"
                          );
                          const clientToken =
                            process.env["NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN"];
                          assert(
                            clientToken != null,
                            ".env variable NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN must be set"
                          );
                          // Ensure we don't initialize multiple times
                          datadogRum.init({
                            applicationId,
                            clientToken,
                            site: "datadoghq.com",
                            service: "savvy-pinetree-app",
                            env: "production",
                            version: "1.0.0",
                            sampleRate: 100,
                            premiumSampleRate: 100,
                            trackInteractions: true,
                            trackFrustrations: true,
                            defaultPrivacyLevel: "mask-user-input",
                          });

                          datadogRum.setUser({
                            id: user.id?.toString(),
                            name: `${user.firstName} ${user.lastName}`,
                            email: user.email,
                            login_as_session: !!loggedInAsAdvisorID(),
                            role: user.role,
                          });
                          datadogRum.startSessionReplayRecording();
                        }
                      }
                      setInitedLibs(true);
                    }
                    return getLayout(
                      <>
                        <Component {...pageProps} />
                        {router.pathname.startsWith("/advisor") && (
                          <ChatbotWrapper />
                        )}
                      </>
                    );
                  }}
                </AuthConsumer>
              </ThemeProvider>
            </AuthProvider>
          </ApolloProvider>
        </LDProvider>
      </LocalizationProvider>
    </>
  );
};

export default App;
