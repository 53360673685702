import assert from "assert";
import { XRouter } from "hooks/use-router";
import { NextRouter } from "next/router";
import { JWTContextUser } from "shared/api/user-api";
import { getClientStageRedirectRoute } from "shared/utils/routing";

export function getReturnUrl(
  router: NextRouter | XRouter,
  user: JWTContextUser | null,
  isSmallScreen: boolean
): string {
  const returnUrl = router.query["returnUrl"];
  assert(
    typeof returnUrl === "string" || returnUrl === undefined,
    `Invalid type of returnUrl in URL: ${typeof returnUrl}`
  );
  const defaultReturnUrl =
    isSmallScreen && user?.personalPortfolioId != null && user?.role !== "NON_ADVISORY_USER"
      ? `/savvy-portfolio/${user.personalPortfolioId}`
      : "/portfolio";
  return (
    returnUrl ||
    getClientStageRedirectRoute(router, user, isSmallScreen) ||
    defaultReturnUrl
  );
}
